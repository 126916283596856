import React from 'react'
import { Link } from 'react-router-dom'

const Header = () => (
    <div className='row d-flex justify-content-start mx-0 bg-secondary'>
        <div className='col-md-6'>
            <Link className='navbar-brand' to='/app'><span className="text-white">Tapak</span></Link>
        </div>
    </div>
)

export default Header