import axios from 'axios'
import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import IssuesByUsernameCard from './IssuesByUsernameCard'

class IssuesList extends Component {
    constructor () {
        super()
        this.state = {
            hasLoaded: false,
            issues: []
        }
    }

    componentDidMount () {
        axios.get('/access_token')
            .then(response => {
                return axios.get('https://gitlab.com/api/v4/projects/10421163/issues?state=opened&per_page=100&labels=Kewangan', {
                    headers: {'Private-Token': response.data}
                })
            })
            .then(response => {
                this.setState({
                    hasLoaded: true,
                    issues: response.data
                })
            })
            .catch(err => {
                console.log('err', err);
            });
    }

    render () {
        const { hasLoaded, issues } = this.state
        return (
            <div className='py-4'>
                <div className='row mb-3 mx-0'>
                    <div className='col-sm-12'>
                        <h5 className="mb-4">Projek: Kewangan</h5>
                    </div>
                </div>
                <div className='row mx-0'>
                    <div className='col-sm-2 mb-3'>
                        <IssuesByUsernameCard
                            hasLoaded={hasLoaded}
                            username='yasir0722'
                            issues={issues.filter((issue) => issue.assignee && issue.assignee.username === 'yasir0722')}
                        />
                    </div>
                    <div className='col-sm-2 mb-3'>
                        <IssuesByUsernameCard
                            hasLoaded={hasLoaded}
                            username='hafizabdul6'
                            issues={issues.filter((issue) => issue.assignee && issue.assignee.username === 'hafizabdul6')}
                        />
                    </div>
                    <div className='col-sm-2 mb-3'>
                        <IssuesByUsernameCard
                            hasLoaded={hasLoaded}
                            username='rsmn'
                            issues={issues.filter((issue) => issue.assignee && issue.assignee.username === 'rsmn')}
                        />
                    </div>
                    <div className='col-sm-6 mb-3'>
                        <IssuesByUsernameCard
                            className="card-header bg-light"
                            hasLoaded={hasLoaded}
                            isUnassigned={true}
                            issues={issues.filter((issue) => !issue.assignee)}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

export default IssuesList