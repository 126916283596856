import axios from 'axios'
import React, { Component } from 'react'
import { Link } from 'react-router-dom'

class IssuesByUsernameCard extends Component {
    constructor () {
        super()
        this.getManDays = this.getManDays.bind(this);
    }

    getLabelBackgroundColor(label) {
        switch(label) {
            case 'Critical':
                return {backgroundColor: '#FF0000'}
            case 'Important':
                return {backgroundColor: '#D9534F'};
            case 'bugsnag':
                return {backgroundColor: '#428BCA'};
            default:
                return {};
        }
    }

    getManDays() {
        const { hasLoaded, username = '', issues } = this.props
        let manDays = issues
            .filter((issue) => {
                return (
                    (issue.title.split(/^.*?\[[^\]]*\].*?\[([^\]]*)\]/g))[1]
                )
            })
            .reduce((sum, issue) => {
                let days = parseInt((issue.title.split(/^.*?\[[^\]]*\].*?\[([^\]]*)\]/g))[1]);
                return days + sum;
            }, 0);

        return hasLoaded && issues.length && manDays > 0 && (
            <h3><span className="badge badge-pill badge-warning float-right">
                {manDays}d
            </span></h3>
        );
    }

    render () {
        const { hasLoaded, username = '', issues, isUnassigned = false } = this.props
        return (
            <div className='card'>
                <div className={'card-header ' + (isUnassigned ? 'bg-white' : 'bg-secondary text-white')}><h3 className="float-left mb-0">@{username ? username : 'Unassigned'}</h3></div>
                {
                    hasLoaded && (
                        !issues.length ? (
                            <div className='card-body pb-0'>
                                <p>No issues</p>
                            </div>
                        ) : (
                            <div className='card-body p-0'>
                                <ul className='list-group list-group-flush'>
                                    {issues
                                        .map(issue => (
                                        <div
                                            className='list-group-item list-group-item-action'
                                            key={issue.id}
                                        >
                                            <div className='d-flex justify-content-between align-items-center'>
                                                <p className="mb-0">
                                                    {issue.title} <a target="_blank" href={`https://gitlab.com/teranerve/kewangan/-/issues/${issue.iid}`}>#{issue.iid}</a> {issue.assignee && <span style={{color: 'blue'}}>@{issue.assignee.username}</span>} {issue.closed_at}
                                                    {issue.labels.length &&
                                                        issue.labels.filter(label => label != 'Kewangan').map(label => (
                                                            <span key={label} className="badge badge-secondary mr-1" style={this.getLabelBackgroundColor(label)}>{label}</span>
                                                        ))
                                                    }
                                                </p>
                                                {(issue.title.split(/^.*?\[[^\]]*\].*?\[([^\]]*)\]/g))[1] && (
                                                    <h5>
                                                        <span className='badge badge-secondary badge-pill'>
                                                            {(issue.title.split(/^.*?\[[^\]]*\].*?\[([^\]]*)\]/g))[1]}
                                                        </span>
                                                    </h5>
                                                )}
                                            </div>
                                            {issue.description && issue.description.match(/(\* \[ \].+|\* \[x\].+)/g) && (
                                                issue.description.match(/(\* \[ \].+|\* \[x\].+)/g).map((task) => (
                                                    <p className="mb-0" key={task}>
                                                        {task.replace("* [ ]", "⬜️").replace("* [x]", "✅")}
                                                    </p>
                                                ))
                                            )}
                                        </div>
                                    ))}
                                </ul>
                            </div>
                        )
                    )
                }
            </div>
        )
    }
}

export default IssuesByUsernameCard